import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';

type CommunicationMethodsProps = {
    communicationMethods: string[];
    onToggle: (method: string, checked: boolean) => void;
};

enum CommunicationMethod {
    PHONE = 'phone',
    DOOR_TO_DOOR = 'door_to_door',
    LEAFLET = 'leaflet',
}

const COMMUNICATION_METHODS = [
    CommunicationMethod.PHONE,
    CommunicationMethod.DOOR_TO_DOOR,
    CommunicationMethod.LEAFLET,
];


const CommunicationMethods: React.FC<CommunicationMethodsProps> = ({ communicationMethods, onToggle }) => {
    const { t } = useTranslation([Namespace.REPORT]);

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography variant="subtitle1">
                {t('communication.methods.question', { ns: Namespace.REPORT })}
            </Typography>
            <FormGroup row>
                {COMMUNICATION_METHODS.map((method) => (
                    <FormControlLabel
                        key={method}
                        control={
                            <Checkbox
                                checked={communicationMethods.includes(method)}
                                onChange={(e) => onToggle(method, e.target.checked)}
                            />
                        }
                        label={t(`communication.methods.${method}`, { ns: Namespace.REPORT })}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default CommunicationMethods;
