import { CheckRounded } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { getPictureURL, StorageType } from '../../helpers/storage';
import { Namespace } from '../../locales/translations';
import { MissionsData, MissionStatus } from '../../models/Missions';

type MissionCardProps = {
    missiondata: MissionsData;
};

function MissionCard({ missiondata }: MissionCardProps) {
    const { t } = useTranslation([Namespace.ADDRESSES, Namespace.DATES]);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/${AppRoutes.MISSIONS}/${missiondata.ID}/${AppRoutes.DETAIL}`);
    };

    const [pictureURL, setPictureURL] = useState<string | null>(null);

    useEffect(() => {
        if (missiondata?.mapImageURL) {
            getPictureURL(missiondata.mapImageURL, StorageType.MISSIONS)
                .then((picURL) => {
                    setPictureURL(picURL);
                });
        } else {
            setPictureURL(null);
        }
    }, [missiondata?.mapImageURL]);

    const totalAddresses = missiondata.addressesCount || 0;
    const visitedAddresses = missiondata.addressesVisitedCount || 0;
    const allVisited = visitedAddresses === totalAddresses && totalAddresses > 0;

    return (
        <Card
            onClick={handleClick}
            sx={{
                cursor: 'pointer',
                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.20)',
                transition: 'box-shadow 0.3s ease-in-out',
                position: 'relative',
            }}>
            <CardContent>
                <Box
                    position="relative"
                    sx={{
                        height: 180,
                        width: '100%',
                        mb: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #ddd',
                        backgroundColor: '#f5f5f5',
                        overflow: 'hidden',
                    }}
                >
                    {pictureURL ? (
                        <Box
                            component="img"
                            sx={{
                                height: "100%",
                                width: '100%',
                                objectFit: 'cover'
                            }}
                            src={pictureURL}
                        />
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                            {t('no_address_added', { ns: Namespace.ADDRESSES, })}
                        </Typography>
                    )}
                    
                    {missiondata.status === MissionStatus.COMPLETED && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.80)',
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CheckRounded
                                sx={{
                                    color: '#5FB474',
                                    fontSize: 45,
                                }}
                            />
                            <Typography variant="h5" color='#5FB474' sx={{ fontSize: "16", fontWeight: '900', fontStyle: 'normal' }}>
                                {t('Completed')}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Typography variant="h4" paddingBottom={1} margin={0} component="div">
                    {missiondata.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {t('created_at', { ns: Namespace.DATES, date: missiondata.createdAt })}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    {allVisited && <CheckRounded sx={{ color: '#5FB474', marginRight: 1 }} />}
                    {t('addresses_to_visit', { ns: Namespace.ADDRESSES, count: visitedAddresses, total: totalAddresses })}
                </Typography>
                <Typography variant="h5" color="text.secondary">
                    {t('deadline', { ns: Namespace.DATES, date: missiondata.deadline })}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default MissionCard;
