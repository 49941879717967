import React from 'react';
import { Check, ChevronLeft } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MissionsMethods from '../../../actions/missions';
import { AppRoutes } from '../../../constants/routes';
import { Namespace } from '../../../locales/translations';
import { MissionsData, MissionStatus } from '../../../models/Missions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ActionButton from '../../_include/Buttons/ActionButton';
import MissionAddressList from '../MissionAddressList';
import MissionMap from '../MissionMap';
import { useNavigate } from 'react-router-dom';
import { selectAllMissionAddresses } from '../../../store/reducers/missions/addresses';

interface MissionDetailContentProps {
    mission: MissionsData;
}

const MissionDetailContent: React.FC<MissionDetailContentProps> = ({ mission }) => {
    const { t } = useTranslation([Namespace.ADDRESSES, Namespace.ACTIONS, Namespace.DATES]);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const loadingStatus = useAppSelector((state) => state.mission.selected.loadingStatus);
    const missionAddresses = useAppSelector((state) => selectAllMissionAddresses(state));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleMarkAsDone = () => {
        if (partnerID) {
            dispatch(
                MissionsMethods.updateMission(partnerID, mission.ID, {
                    status: MissionStatus.COMPLETED,
                })
            );
            navigate(`/${AppRoutes.MISSIONS}`);
        }
    };

    return (
        <Box padding={2} pb={10} sx={{ height: '100vh', overflowY: 'auto' }}>
            <Box display="flex" alignItems="center" gap={2}>
                <IconButton
                    onClick={() => navigate(`/${AppRoutes.MISSIONS}`)}
                    sx={{ padding: 0 }}
                >
                    <ChevronLeft />
                </IconButton>
                <Typography variant="h4" gutterBottom sx={{ pb: 1.5 }}>
                    {mission.name || t('Unnamed Mission')}
                </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
                {t('created_at', { ns: Namespace.DATES, date: mission.createdAt })}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                {t('addresses_to_visit', {
                    ns: Namespace.ADDRESSES,
                    count: missionAddresses.filter((a) => a.visited).length || 0,
                    total: missionAddresses.length || 0,
                })}
            </Typography>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
                {t('deadline', { ns: Namespace.DATES, date: mission.deadline })}
            </Typography>
            {missionAddresses.length > 0 ? (
                <Box sx={{ height: 500, width: '100%' }}>
                    <MissionMap
                        mapID={mission.ID}
                        width={1000}
                        height={500}
                        points={missionAddresses}
                        showMapControls
                    />
                </Box>
            ) : (
                <Typography variant="body2" color="text.secondary">
                    {t('No addresses available for this mission.')}
                </Typography>
            )}
            <MissionAddressList />
            {mission.status !== MissionStatus.COMPLETED && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <ActionButton
                        onClick={handleMarkAsDone}
                        color="success"
                        startIcon={<Check />}
                        loading={loadingStatus}
                    >
                        {t('mark_as_done', { ns: Namespace.ACTIONS })}
                    </ActionButton>
                </Box>
            )}
        </Box>
    );
};

export default MissionDetailContent;
