import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import { DbCollection } from "../constants/db";
import { AddressWithErrors } from "../models/Missions";
import { MissionsActions } from "../store/reducers/missions/list";
import { AppDispatch } from "../store/store";
import { handleAPIError } from "./actions";
import { getStorageURL, StorageType, uploadFile } from "../helpers/storage";
import { ReportFormData } from "../models/Forum";
import { showSuccess } from "../store/reducers/snacks";
import { MissionAddressesActions } from "../store/reducers/missions/addresses";
import i18next, { Namespace } from "../locales/translations";

/**
 * Serialize a Mission's Address data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): AddressWithErrors;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): AddressWithErrors;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as AddressWithErrors;
    const missionAddressData: AddressWithErrors = {
        ...data,
        ID: dbDoc.id,
    };

    return missionAddressData;
}

/**
 * Retrieves all addresses for a specific mission.
 * @param {string} partnerID - The ID of the partner.
 * @param {string} missionID - The ID of the mission.
 * @returns {Promise<AddressWithErrors[]>} The list of address documents.
 */
const getAddresses = (partnerID: string, missionID: string) => async (dispatch: AppDispatch) => {
    dispatch(MissionAddressesActions.startLoading());

    try {
        const db = getFirestore();
        const addressesRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS, missionID, DbCollection.SENSITIZATION_ADDRESSES);
        const addressesSnapshot = await getDocs(addressesRef);

        const missionAddresses: AddressWithErrors[] = addressesSnapshot.docs
            .map(fromDbDoc);

        dispatch(MissionAddressesActions.setAddresses(missionAddresses));
        return missionAddresses;
    } catch (e) {
        dispatch(handleAPIError(e, "fetching mission addresses", MissionsActions.setError));
        return null;
    }
};

/**
 * Action to update mission addresses in Firestore individually, including image uploads.
 * 
 * @param partnerID - The ID of the partner.
 * @param missionID - The ID of the mission.
 * @param updatedMissionAddress - A partial object of AddressWithErrors to update.
 * @param images - An array of files representing the images to upload.
 */
const updateMissionAddress = (
    partnerID: string,
    missionID: string,
    addressID: string,
    updatedMissionAddress: ReportFormData,
    images: File[]
) => async (dispatch: AppDispatch) => {

    try {
        dispatch(MissionAddressesActions.startLoading());
        const db = getFirestore();

        // Reference to the 'sensitization_addresses' subcollection
        const addressesCollectionRef = collection(
            db,
            DbCollection.PARTNERS,
            partnerID,
            DbCollection.MISSIONS,
            missionID,
            DbCollection.SENSITIZATION_ADDRESSES
        );

        // Reference to the specific address document
        const addressDocRef = doc(addressesCollectionRef, addressID);

        const addressImagesUrl = getStorageURL(StorageType.MISSION_ADDRESSES);

        // Handle image uploads
        const uploadedImageURLs = await Promise.all(
            images.map(async (image) => {
                const filepath = `${missionID}/${addressID}/${Date.now()}_${image.name}`;
                const storagePath = `${addressImagesUrl}/${filepath}`;
                await uploadFile(image, storagePath);
                return filepath;
            })
        );

        // Update the report with uploaded image URLs directly inside the FormData
        const updatedReport: ReportFormData = {
            ...updatedMissionAddress, // All fields from FormData
            imageURLs: uploadedImageURLs, // Attach the uploaded image URLs here
        };

        // Update the address document with merged data
        await setDoc(addressDocRef, { visited: true, report: updatedReport }, { merge: true });

        dispatch(MissionAddressesActions.updateAddressVisitedStatus({ addressID, changes: { visited: true }, }));
        dispatch(showSuccess(i18next.t('address_report_updated', { ns: Namespace.SNACKS })));

    } catch (error) {
        let errorMessage = i18next.t('unknown_error', { ns: Namespace.SNACKS });

        dispatch(handleAPIError(error, errorMessage, MissionsActions.setError));
    } finally {
        dispatch(MissionAddressesActions.stopLoading());
    }
};


const MissionAddressesMethods = {
    getAddresses,
    updateMissionAddress
};

export default MissionAddressesMethods;
