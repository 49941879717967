import React from 'react';
import { Box } from '@mui/material';
import ActionButton from '../../_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';
import { useAppSelector } from '../../../store/hooks';

type SubmitButtonProps = {
    onSubmit: () => void;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({ onSubmit }) => {
    const { t } = useTranslation([Namespace.ACTIONS]);
    const loading = useAppSelector(state => state.mission.addresses.loading)!;


    return (
        <Box display="flex" justifyContent="center" mt={2}>
            <ActionButton onClick={onSubmit} color="success" loading={loading} fullWidth>
                {t('submit', { ns: Namespace.ACTIONS })}
            </ActionButton>
        </Box>
    );
};

export default SubmitButton;
