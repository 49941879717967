import React, { useState } from 'react';
import { Box, Grid, IconButton, Typography, FormControl } from '@mui/material';
import Close from '@mui/icons-material/Close';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';

const MAX_IMAGES = 10;
const MAX_TOTAL_SIZE_MB = 40;

type ImagePickerProps = {
    images: File[];
    setImages: (images: File[]) => void;
};

const ImagePicker: React.FC<ImagePickerProps> = ({ images, setImages }) => {
    const { t } = useTranslation([Namespace.REPORT]);

    // Combined state for totalSizeMB and errorMessage
    const [pickerState, setPickerState] = useState<{ totalSizeMB: number; errorMessage: string | null }>({
        totalSizeMB: 0,  // Track total size of uploaded images
        errorMessage: null,
    });

    // Handle image uploads
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newImages = Array.from(files);

            // Check for duplicate images (by name and size)
            const duplicateImage = newImages.some((newImage) =>
                images.some((image) => image.name === newImage.name && image.size === newImage.size)
            );
            if (duplicateImage) {
                setPickerState((prevState) => ({
                    ...prevState,
                    errorMessage: t('duplicate_image', { ns: Namespace.REPORT }),
                }));
                return;
            }

            const newImagesSizeMB = newImages.reduce((acc, file) => acc + file.size / (1024 * 1024), 0); // Size in MB

            // Check if adding new images exceeds the total size limit
            if (pickerState.totalSizeMB + newImagesSizeMB > MAX_TOTAL_SIZE_MB) {
                setPickerState((prevState) => ({
                    ...prevState,
                    errorMessage: t('total_size_exceeded', { ns: Namespace.REPORT }) + MAX_TOTAL_SIZE_MB + "MB",
                }));
                return;
            }

            setPickerState((prevState) => ({
                ...prevState,
                errorMessage: null, // Reset error if valid
                totalSizeMB: prevState.totalSizeMB + newImagesSizeMB, // Update total size
            }));

            setImages([...images, ...newImages]);
        }
    };

    // Handle removing images and updating the total size
    const handleRemoveImage = (index: number) => {
        const removedImage = images[index];
        const removedImageSizeMB = removedImage.size / (1024 * 1024); // Convert size to MB

        // Update images and total size
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
        setPickerState((prevState) => ({
            ...prevState,
            totalSizeMB: prevState.totalSizeMB - removedImageSizeMB, // Subtract the removed image's size from total
            errorMessage: null,
        }));
    };

    // Handle click when Add Image button is disabled
    const handleDisabledClick = () => {
        if (images.length >= MAX_IMAGES) {
            setPickerState((prevState) => ({
                ...prevState,
                errorMessage: t('Maximum number of images reached', { ns: Namespace.REPORT }),
            }));
        }
    };

    return (
        <FormControl component="fieldset" sx={{ mb: 8 }}>
            <Typography variant="subtitle1" sx={{ my: 1 }}>{t('add_images', { ns: Namespace.REPORT })}</Typography>
            <Grid container spacing={2}>
                <Grid item>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={100}
                        height={100}
                        border="2px solid #ddd"
                        borderRadius="8px"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (images.length < MAX_IMAGES) {
                                document.getElementById("image-upload")?.click();
                            } else {
                                handleDisabledClick();
                            }
                        }}
                        sx={{ opacity: images.length >= MAX_IMAGES ? 0.5 : 1, }}
                    >
                        <AddPhotoAlternate style={{ fontSize: '48px', color: '#999' }} />
                    </Box>
                </Grid>
                <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                />
                {images.map((image, index) => (
                    <Grid item key={image.name}>
                        <Box
                            position="relative"
                            width={100}
                            height={100}
                            border="1px solid #ddd"
                            borderRadius="8px"
                        >
                            <img
                                src={URL.createObjectURL(image)}
                                alt={t('preview', { ns: Namespace.REPORT }) + index}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <IconButton
                                size="small"
                                onClick={() => handleRemoveImage(index)}
                                style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                    backgroundColor: 'white',
                                    boxShadow: '0px 1px 4px rgba(0,0,0,0.2)',
                                }}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ marginTop: 2 }}>
                {pickerState.errorMessage && (
                    <Typography color="error" variant="body2">
                        {pickerState.errorMessage}
                    </Typography>
                )}
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                    {t('total_size', { ns: Namespace.REPORT })}: {pickerState.totalSizeMB.toFixed(2)} MB / {MAX_TOTAL_SIZE_MB} MB
                </Typography>
            </Box>
        </FormControl>
    );
};

export default ImagePicker;
