import React from 'react';
import { FormControl, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';

type CommentSectionProps = {
    label?: string;
    placeholder?: string; 
    value: string;
    rows: number;
    wordLimit: number;
    nameSpace: Namespace[];
    onChange: (value: string) => void;
};

const CommentSection: React.FC<CommentSectionProps> = ({ label, placeholder, value, rows, wordLimit, nameSpace,onChange }) => {
    const { t } = useTranslation(nameSpace);

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            {label && (
                <Typography variant="subtitle1">
                    {t(label, { ns: nameSpace[0] })}
                </Typography>
            )}
            <TextField
                inputProps={{ maxLength: wordLimit }}
                placeholder={placeholder ? t(placeholder, { ns: nameSpace[1] })! : ''}
                multiline
                rows={rows}
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                helperText={value.length  +" / " + wordLimit}
            />
        </FormControl>
    );
};

export default CommentSection;
