import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';

type QuestionWithRadioProps = {
    question: string;
    value: boolean | null;
    onChange: (value: boolean) => void;
};

const QuestionWithRadio: React.FC<QuestionWithRadioProps> = ({ question, value, onChange }) => {
    const { t } = useTranslation([Namespace.REPORT, Namespace.ACTIONS]);

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography variant="subtitle1">{t(question, { ns: Namespace.REPORT })}</Typography>
            <RadioGroup
                row
                value={value === null ? "" : value}
                onChange={(e) => onChange(e.target.value === "true")}
            >
                <FormControlLabel value={true} control={<Radio />} label={t('yes', { ns: Namespace.ACTIONS })} />
                <FormControlLabel value={false} control={<Radio />} label={t('no', { ns: Namespace.ACTIONS })} />
            </RadioGroup>
        </FormControl>
    );
};

export default QuestionWithRadio;
