import React, { useState } from 'react';
import { ListItem, Checkbox, ListItemText, Divider } from '@mui/material';
import { formatAddress } from '../../helpers/geo';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { MissionStatus } from '../../models/Missions';
import { useAppSelector } from '../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { ChevronRight } from '@mui/icons-material';
import AddressReport from './AddressReport/AddressReport';
import { selectMissionAddressById } from '../../store/reducers/missions/addresses';
import { EntityId } from '@reduxjs/toolkit';

interface MissionAddressItemProps {
    ID: EntityId;
}

const MissionAddressItem: React.FC<MissionAddressItemProps> = ({ ID }) => {
    const { t } = useTranslation([Namespace.GLOSSARY]);
    const navigate = useNavigate();
    const { missionID } = useParams<{ missionID: string }>();
    const missionCompleted = useAppSelector((state) => state.mission.selected.selectedMission?.status === MissionStatus.COMPLETED);
    const addressData = useAppSelector(state => selectMissionAddressById(state, ID))!;

    // State to control the dialog visibility
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleCheckboxChange = () => {
        if (addressData.visited) {
            setIsDialogOpen(false);
        }
        else {
            setIsDialogOpen(true);
        }
    };

    const handleAddressDetailClick = () => {
        if (missionID) {
            navigate(
                `/${AppRoutes.MISSIONS}/${missionID}/${AppRoutes.BATCH_DETAIL}/${addressData.addressKey}`
            );
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const errorsCountLabel = t('errors_count', {
        count: addressData.errorsCount,
        ns: Namespace.GLOSSARY,
    });
    const imagesCountLabel = t('images_count', {
        count: addressData.batchesCount,
        ns: Namespace.GLOSSARY,
    });

    return (
        <>
            <ListItem
                onClick={handleAddressDetailClick}
                sx={{
                    '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'pointer',
                    },
                }}
                secondaryAction={
                    <ChevronRight />
                }
            >
                {!missionCompleted && (
                    <Checkbox
                        edge="start"
                        color='success'
                        disableRipple
                        checked={addressData.visited}
                        onChange={handleCheckboxChange}
                        onClick={(event) => event.stopPropagation()}
                    />
                )}
                <ListItemText
                    primary={formatAddress(addressData.address)}
                    secondary={`${errorsCountLabel} / ${imagesCountLabel}`}
                />
            </ListItem>
            <Divider />
            {/* Conditionally render the AddressReport dialog */}
            {isDialogOpen && (
                <AddressReport
                    open={isDialogOpen}
                    address={formatAddress(addressData.address)}
                    onClose={handleCloseDialog}
                    addressID={addressData.ID}
                />
            )}

        </>
    );
};

export default MissionAddressItem;
