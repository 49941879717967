import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, getDoc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import { DbCollection } from "../constants/db";
import { MissionDbData, MissionsData } from "../models/Missions";
import { MissionsActions } from "../store/reducers/missions/list";
import { SelectedMissionActions } from "../store/reducers/missions/selected";
import { AppDispatch } from "../store/store";
import { handleAPIError } from "./actions";

/**
 * Serialize a Mission's data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): MissionsData;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): MissionsData | null;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as MissionDbData;

    const missionData: MissionsData = {
        ...data,
        ID: dbDoc.id,
        createdAt: data.createdAt ? moment(data.createdAt).format('YYYY-MM-DDTHH:mm:ss') : undefined,
        deadline: data.deadline ? moment(data.deadline).format('YYYY-MM-DDTHH:mm:ss') : undefined,
        addressesCount: data.addressesCount,
        addressesVisitedCount: data.addressesVisitedCount,

    };

    return missionData;
}

/**
 * Retrieves a list of missions for a specific partner.
 * @param {AppDispatch} dispatch - The dispatch function from the Redux store.
 * @param {string} partnerID - The ID of the partner.
 * @param {string} email - The email of the user.
 * @returns {Promise<MissionsData[]>} The list of missions.
 */
const getMissions = (partnerID: string, email: string) => async (dispatch: AppDispatch) => {
    dispatch(MissionsActions.startLoadingList());

    try {
        const db = getFirestore();
        // Directly access the specific partner's batches collection
        const missionsRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS);
        const missionDocs = await getDocs(missionsRef); // Fetch all documents

        // Filter missions to only include those assigned to the given email
        const missions: MissionsData[] = missionDocs.docs
            .map(fromDbDoc) // Convert documents to mission data
            .filter((mission): mission is MissionsData =>
                mission !== null &&
                mission.assigned!.some(user => user.email === email)
            ); // Filter based on email

        dispatch(MissionsActions.setList(missions));
        return missions;
    } catch (e) {
        const error = e as Error;
        dispatch(MissionsActions.setError(error.message));
        return [];
    }
};

/**
  * Retrieves a single missions for a specific partner.
  * @param dispatch - The dispatch function from the Redux store.
  * @param partnerID - The ID of the partner.
  * @param missionID - The ID of the mission to retrieve.
  * @returns The list of missions.
 */
const retrieve = (partnerID: string, missionID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoading());

    try {
        const db = getFirestore();
        const missionRef = doc(db, `${DbCollection.PARTNERS}/${partnerID}`, DbCollection.MISSIONS, missionID);
        const missionDoc = await getDoc(missionRef);

        // Filter out null values
        const mission = fromDbDoc(missionDoc);

        dispatch(SelectedMissionActions.setSelected(mission));
        return mission;
    } catch (e) {
        dispatch(handleAPIError(e, "retrieving mission", MissionsActions.setError));
        return null;
    }
};


const updateMission = (partnerID: string, missionID: string, updatedMission: Partial<MissionDbData>) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoadingStatus());
    try {
        const db = getFirestore();
        const missionRef = doc(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS, missionID);

        // Update the mission document in Firestore with the new data
        const sanitizedData = _.pickBy(updatedMission, value => value !== undefined)
        await setDoc(missionRef, sanitizedData, { merge: true });
        dispatch(SelectedMissionActions.stopLoadingStatus());

    } catch (e) {
        dispatch(handleAPIError(e, "updating mission", MissionsActions.setError));
        return null;
    }
    dispatch(SelectedMissionActions.stopLoadingStatus());
};


const select = (mission: MissionsData) => (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.setSelected(mission));
};

const MissionsMethods = {
    select,
    getMissions,
    retrieve,
    updateMission,
};

export default MissionsMethods;
