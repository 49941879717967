import React from 'react';
import { List } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import MissionAddressItem from './MissionAddressItem';
import {  selectMissionAddressesIds } from '../../store/reducers/missions/addresses';

const MissionAddressList: React.FC = () => {
    const addresses = useAppSelector(state => selectMissionAddressesIds(state));

    return (
        <List>
          {addresses.map((addressID) => (
                <MissionAddressItem key={addressID} ID={addressID}/>
            ))}
        </List>
    );
};

export default MissionAddressList;
