import React from 'react';
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions/transition';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type DialogWrapperProps = {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

const DialogWrapper: React.FC<DialogWrapperProps> = ({ open, onClose, children }) => {
    return (
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar elevation={0} sx={{ backgroundColor: 'transparent', position: "relative" }}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <Close color="action" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{ p: 3, mb: 3 }}>
                {children}
            </Box>
        </Dialog>
    );
};

export default DialogWrapper;
