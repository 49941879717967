import { Box, CircularProgress, } from "@mui/material";

type SectionLoaderProps = {
    size?: number | string;
}

function SectionLoader(props: SectionLoaderProps) {
    const { size } = props;

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "999",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
        }}>
            <CircularProgress size={size || 40} />
        </Box>
    );
}

export default SectionLoader;