import React from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { SentimentNeutral, SentimentVeryDissatisfied, SentimentSatisfiedAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';

export enum ResponsivenessLevel {
    Responsive = 'responsive',
    Neutral = 'neutral',
    NotResponsive = 'not_responsive',
}

type MoodSelectorProps = {
    question: string;
    value: ResponsivenessLevel | null;
    onChange: (value: ResponsivenessLevel) => void;
};

const MoodSelector: React.FC<MoodSelectorProps> = ({ question, value, onChange }) => {
    const { t } = useTranslation([Namespace.REPORT]);

    // Icon size and colors for each mood
    const moodIcons = [
        {
            value: ResponsivenessLevel.Responsive,
            icon: <SentimentSatisfiedAlt fontSize="inherit" />,
            color: '#28b01e',
        },
        {
            value: ResponsivenessLevel.Neutral,
            icon: <SentimentNeutral fontSize="inherit" />,
            color: '#dbcf1a',
        },
        {
            value: ResponsivenessLevel.NotResponsive,
            icon: <SentimentVeryDissatisfied fontSize="inherit" />,
            color: '#f55656',
        },
    ];

    return (
        <FormControl component="fieldset" sx={{ mb: 3 }}>
            <Typography variant="subtitle1">{t(question, { ns: Namespace.REPORT })}</Typography>
            <RadioGroup
                row
                value={value}
                onChange={(e) => onChange(e.target.value as ResponsivenessLevel)}
                sx={{ display: 'flex', gap: 2, p: 1 }}
            >
                {moodIcons.map((mood) => (
                    <FormControlLabel
                        key={mood.value}
                        value={mood.value}
                        control={<Radio sx={{ display: 'none' }} />} // Hide default radio button
                        label={
                            <Box
                                sx={{
                                    color: value === mood.value ? mood.color : 'grey',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '60px',
                                    transition: 'color 0.3s',
                                }}
                            >
                                {mood.icon}
                            </Box>
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default MoodSelector;